// TODO somewhere
var styles_base = styles_base || {}
styles_base.primary = '#347baf'
styles_base.gray = '#777'
styles_base.Oswald = 'Oswald, sans-serif'
styles_base.Roboto = 'Roboto, sans-serif'

// TODO shove in some js util class

// https://speakerdeck.com/vjeux/react-css-in-js
function m() {
  var res = {}
  for (var i = 0; i < arguments.length; ++i) {
    if (arguments[i]) {
      _.extend(res, arguments[i])
    }
  }
  return res
}

// TODO LOOK INTO CONVERTING USE OF TABLE ITEM CODE INTO TABLE ID

var TableCombos = TableCombos || {}

TableCombos.App = React.createClass({
  propTypes: {
    url_key: React.PropTypes.string.isRequired,
  },
  getInitialState: function () {
    return {
      layouts: [],
      selected_layout_index: 0,
      rooms: [],
      selected_room_index: 0,
      selected_table_ids: {},
      selected_combo: null,
      slideout_open: false,
      is_initializing: true,
      id_to_code: {},
    }
  },
  componentDidMount: function () {
    var params = $.param({ venue: this.props.url_key })
    var get_layouts = $.get('/api-yoa/floorplan_layouts', params)
    var get_table_items = $.get('/manager/' + this.props.url_key + '/table_items')
    var get_rooms = $.get('/api-yoa/floorplan_rooms', params)

    $.when(get_layouts, get_table_items, get_rooms).done(
      function (layouts_resp, table_items_resp, rooms_resp) {
        if (!this.isMounted()) {
          console.log('Something went wrong! Trying to set state to unmounted dom.')
          return
        }

        var layouts = layouts_resp[0].data
        var table_items = table_items_resp[0].payload.table_items

        var tables = []
        var id_to_code = {}
        for (var i = 0; i < layouts.length; ++i) {
          // layouts[i].table_items_config could contain tables that were deleted
          // table_items only returns undeleted tables
          // create a new dict that only contains undeleted tables
          var filtered_table_items_config = {}
          for (var j = 0; j < table_items.length; ++j) {
            var item = table_items[j]
            var table_id = item.id
            var config_item = layouts[i].table_items_config[table_id]
            // disabled tables are not returned by get_layouts
            if (config_item) {
              filtered_table_items_config[table_id] = config_item
              filtered_table_items_config[table_id].code = item.item_code
              filtered_table_items_config[table_id].id = table_id
              id_to_code[table_id] = item.item_code
            }
          }
          layouts[i].table_items_config = filtered_table_items_config
        }

        this.setState({
          layouts: layouts,
          rooms: rooms_resp[0].data,
          is_initializing: false,
          id_to_code: id_to_code,
        })
      }.bind(this)
    )
  },
  selectLayout: function (layout_id) {
    var layout_index = 0
    for (var i = 0; i < this.state.layouts.length; ++i) {
      if (this.state.layouts[i].layout_id === layout_id) {
        layout_index = i
        break
      }
    }
    this.setState({
      selected_layout_index: layout_index,
      selected_table_ids: {},
    })
  },
  selectRoom: function (room_id) {
    var selected_room_index = 0
    for (var i = 0; i < this.state.rooms.length; ++i) {
      if (this.state.rooms[i].id === room_id) {
        this.setState({
          selected_room_index: i,
        })
        return
      }
    }
  },
  clickCombo: function (combo) {
    var selected_table_ids = {}
    for (var i in combo.table_ids) {
      selected_table_ids[combo.table_ids[i]] = true
    }

    this.setState({
      selected_table_ids: selected_table_ids,
      selected_combo: combo.label,
    })
  },
  clickTable: function (id) {
    var new_value = true
    if (this.state.selected_table_ids[id]) {
      new_value = null
    }

    var modification = {}
    modification[id] = { $set: new_value }

    this.setState({
      selected_table_ids: React.addons.update(this.state.selected_table_ids, modification),
      selected_combo: null,
    })
  },
  openSlideOut: function () {
    this.setState({
      slideout_open: true,
    })
  },
  closeSlideOut: function (clear_selected) {
    this.setState({
      slideout_open: false,
      selected_table_ids: {},
    })
  },
  addCombo: function (table_name, party_size_min, party_size_max) {
    var table_ids = []
    for (var id in this.state.selected_table_ids) {
      if (this.state.selected_table_ids[id]) {
        table_ids.push(id)
      }
    }
    var new_combo = {
      label: table_name,
      table_ids: table_ids,
      party_size_min: party_size_min,
      party_size_max: party_size_max,
    }
    // cannot update this.state directly; deep-copying first...
    var updated_layouts = []
    for (var i = 0; i < this.state.layouts.length; ++i) {
      updated_layouts.push(jQuery.extend(true, {}, this.state.layouts[i]))
    }
    updated_layouts[this.state.selected_layout_index].table_combos.push(new_combo)
    var layout_id = updated_layouts[this.state.selected_layout_index].id
    var data = JSON.stringify(updated_layouts[this.state.selected_layout_index].table_combos)
    var self = this
    var params = $.param({ venue: this.props.url_key })
    $.ajax({
      url: '/api-yoa/floorplan_layouts/' + layout_id + '/table_combos?' + params,
      type: 'PUT',
      data: data,
      contentType: 'application/json',
      success: function (result) {
        if (result.status === 200) {
          self.setState({
            layouts: React.addons.update(self.state.layouts, { $set: updated_layouts }),
          })
          self.closeSlideOut(true)
          return
        }

        var msg = 'Something went wrong. Combination was not added.'
        if (result.status === 403) {
          msg = 'You do not have permissions to update combinations.'
        }

        // TODO rebuild Interface._alert() as a React component
        Interface._alert(msg)
      },
      error: function (xhr, status, error) {
        Interface._alert('Something went wrong. Combination was not removed.')
        self.closeSlideOut()
      },
    })
  },
  removeCombo: function (table_name) {
    var selected_layout = this.state.layouts[this.state.selected_layout_index]
    var updated_combos = _.filter(selected_layout.table_combos, function (combo) {
      return combo.label != table_name
    })
    var updated_layouts = []
    for (var i = 0; i < this.state.layouts.length; ++i) {
      updated_layouts.push(jQuery.extend(true, {}, this.state.layouts[i]))
    }
    updated_layouts[this.state.selected_layout_index].table_combos = updated_combos
    var layout_id = updated_layouts[this.state.selected_layout_index].id
    var data = JSON.stringify(updated_layouts[this.state.selected_layout_index].table_combos)
    var self = this
    var params = $.param({ venue: this.props.url_key })
    $.ajax({
      url: '/api-yoa/floorplan_layouts/' + layout_id + '/table_combos?' + params,
      type: 'PUT',
      data: data,
      contentType: 'application/json',
      success: function (result) {
        self.setState({
          layouts: React.addons.update(self.state.layouts, { $set: updated_layouts }),
        })
      },
      error: function (xhr, status, error) {
        Interface._alert('Something went wrong. Combination was not removed.')
      },
    })
  },
  render: function () {
    if (this.state.is_initializing) {
      var img_src = this.props.media_url + 'images/new-spinner.gif'
      return (
        <div>
          <img style={{ padding: 20, height: 25 }} src={img_src} />
        </div>
      )
    }

    var self = this
    var selected = _.filter(this.state.selected_table_ids, function (is_selected) {
      return is_selected
    })
    var add_disabled = _.size(selected) < 2

    return (
      <div>
        <TableCombos.Control disabled={add_disabled} openSlideOut={this.openSlideOut} />
        <TableCombos.Main
          {...this.state}
          media_url={this.props.media_url}
          selectLayout={this.selectLayout}
          selectRoom={this.selectRoom}
          clickTable={this.clickTable}
          clickCombo={this.clickCombo}
          removeCombo={this.removeCombo}
        />
        <TableCombos.SlideOut
          selected_table_ids={this.state.selected_table_ids}
          selected_layout={this.state.layouts[this.state.selected_layout_index]}
          disabled={add_disabled}
          slideout_open={this.state.slideout_open}
          id_to_code={this.state.id_to_code}
          addCombo={this.addCombo}
          unselectTable={this.clickTable}
          closeSlideOut={this.closeSlideOut}
        />
      </div>
    )
  },
})

TableCombos.Control = React.createClass({
  propTypes: {
    disabled: React.PropTypes.bool.isRequired,
    openSlideOut: React.PropTypes.func.isRequired,
  },
  getInitialState: function () {
    return { hover: false }
  },
  onMouseOver: function () {
    this.setState({ hover: true })
  },
  onMouseOut: function () {
    this.setState({ hover: false })
  },
  style: {
    float: 'right',
    backgroundColor: styles_base.primary,
    borderRadius: 3,
    width: 180,
    height: 25,
    lineHeight: '25px',
    padding: 5,
    margin: 8,
    color: 'white',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  render: function () {
    return (
      <div id="content-header">
        <h2>TABLE COMBINATIONS</h2>
        <Common.Button label="Add Combination" onClick={this.props.openSlideOut} disabled={this.props.disabled} style={this.style} />
      </div>
    )
  },
})

TableCombos.Main = React.createClass({
  propTypes: {
    layouts: React.PropTypes.array.isRequired,
    rooms: React.PropTypes.array.isRequired,
    selected_layout_index: React.PropTypes.number.isRequired,
    selected_room_index: React.PropTypes.number.isRequired,
    id_to_code: React.PropTypes.object.isRequired,
    clickCombo: React.PropTypes.func.isRequired,
    removeCombo: React.PropTypes.func.isRequired,
    selected_combo: React.PropTypes.string,
  },
  styles: {
    container: { position: 'relative' },
    leftPane: { width: 225, top: 51, position: 'absolute' },
    rightPane: { top: 51, left: 225, position: 'absolute' },
  },
  render: function () {
    if (this.props.layouts.length === 0) {
      return <div>No layouts!!!</div>
    }

    var selected_layout = this.props.layouts[this.props.selected_layout_index]
    var selected_room = this.props.rooms[this.props.selected_room_index]
    var { selected_combo, clickCombo, removeCombo, ...other } = this.props

    return (
      <div style={this.styles.container}>
        <div style={this.styles.leftPane}>
          <TableCombos.List
            table_combos={selected_layout.table_combos}
            selected_combo={selected_combo}
            clickCombo={clickCombo}
            removeCombo={removeCombo}
          />
        </div>
        <div style={this.styles.rightPane}>
          <Floorplan.Layout {...other} />
        </div>
      </div>
    )
  },
})

TableCombos.List = React.createClass({
  propTypes: {
    table_combos: React.PropTypes.array.isRequired,
    clickCombo: React.PropTypes.func.isRequired,
    removeCombo: React.PropTypes.func.isRequired,
    selected_combo: React.PropTypes.string,
  },
  render: function () {
    if (this.props.table_combos.length === 0) {
      return (
        <ul>
          <li>No table combinations set up.</li>
        </ul>
      )
    }

    var styles = {
      list_item: {
        padding: 12,
        margin: 0,
        listStyle: 'none',
        fontSize: 12,
        lineHeight: this.props.row_height + 'px', // lineHeight doesn't add px automatically
        height: this.props.row_height,
        border: '1px solid #e1e1e1',
      },
      selected: {
        backgroundColor: 'rgba(119, 136, 153, 0.3)',
      },
      text_div: {
        float: 'left',
        width: 90,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: this.props.row_height + 'px', // lineHeight doesn't add px automatically
        height: this.props.row_height,
      },
      hover: {
        cursor: 'pointer',
        border: '1px solid rgb(119,136, 153)',
      },
    }

    var self = this
    return (
      <ul>
        {this.props.table_combos.map(function (combo) {
          return (
            <TableCombos.ListItem
              combo={combo}
              selected={combo.label === self.props.selected_combo}
              clickCombo={self.props.clickCombo}
              removeCombo={self.props.removeCombo}
            />
          )
        })}
      </ul>
    )
  },
})

TableCombos.ListItem = React.createClass({
  getDefaultProps: function () {
    return { row_height: 15 }
  },
  getInitialState: function () {
    return { hover: false }
  },
  propTypes: {
    row_height: React.PropTypes.number,
    combo: React.PropTypes.object.isRequired,
    selected: React.PropTypes.bool.isRequired,
    clickCombo: React.PropTypes.func.isRequired,
    removeCombo: React.PropTypes.func.isRequired,
  },
  onMouseOver: function () {
    this.setState({ hover: true })
  },
  onMouseOut: function () {
    this.setState({ hover: false })
  },
  render: function () {
    var styles = {
      list_item: {
        padding: 12,
        margin: 0,
        listStyle: 'none',
        fontSize: 12,
        lineHeight: this.props.row_height + 'px', // lineHeight doesn't add px automatically
        height: this.props.row_height,
        border: '1px solid #e1e1e1',
      },
      selected: {
        backgroundColor: 'rgba(119, 136, 153, 0.3)',
      },
      text_div: {
        float: 'left',
        width: 90,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: this.props.row_height + 'px', // lineHeight doesn't add px automatically
        height: this.props.row_height,
      },
      hover: {
        cursor: 'pointer',
        border: '1px solid rgb(119,136, 153)',
      },
      close_button: {
        float: 'right',
        MozBorderRadius: 12,
        WebkitBorderRadius: 12,
        KhtmlBorderRadius: 12,
        borderRadius: 12,
        height: 12,
        width: 12,
        lineHeight: '11px',
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'black',
        marginTop: 1,
      },
    }

    var capacity = this.props.combo.party_size_min + ' - ' + this.props.combo.party_size_max + ' guests'
    var list_item_style = m(styles.list_item, this.props.selected && styles.selected, this.state.hover && styles.hover)
    var capacity_style = m(styles.text_div, { color: '#999' })

    var self = this

    return (
      <li
        key={this.props.combo.label}
        onClick={function (e) {
          self.props.clickCombo(self.props.combo)
        }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        style={list_item_style}
      >
        <div style={styles.text_div}>{this.props.combo.label}</div>
        <div style={m(styles.text_div, { color: '#999' })}>{capacity}</div>
        <Common.CloseButton
          style={styles.close_button}
          onClick={function (e) {
            self.props.removeCombo(self.props.combo.label)
            e.stopPropagation()
          }}
        />
      </li>
    )
  },
})

// TODO some kind of standardized form so floorplan page can use too
var Floorplan = Floorplan || {}

Floorplan.Layout = React.createClass({
  propTypes: {
    media_url: React.PropTypes.string.isRequired,
    layouts: React.PropTypes.array.isRequired,
    rooms: React.PropTypes.array.isRequired,
    selected_layout_index: React.PropTypes.number.isRequired,
    selected_room_index: React.PropTypes.number.isRequired,
    id_to_code: React.PropTypes.object.isRequired,
    clickTable: React.PropTypes.func.isRequired,
    selectRoom: React.PropTypes.func.isRequired,
    selectLayout: React.PropTypes.func.isRequired,
    selected_table_ids: React.PropTypes.object.isRequired,
  },
  getInitialState: function () {
    return { selected_room: {} }
  },
  render: function () {
    var styles = {
      floorplan_wrapper: {
        background: '#EDEDED url("' + this.props.media_url + 'images/floorplan/black_linen_v2.png") repeat',
      },
      floorplan: {
        position: 'relative',
        clear: 'both',
      },
      button: {
        display: 'inline-block',
        MozBorderRadius: 3,
        WebkitBorderRadius: 3,
        KhtmlBorderRadius: 3,
        borderRadius: 3,
        width: 150,
        height: 15,
        lineHeight: '15px',
        padding: 5,
        margin: '8px 0px 0px 8px',
        color: styles_base.primary,
        backgroundColor: styles_base.gray,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        clear: 'none',
        float: 'left',
      },
      message: {
        clear: 'left',
        color: 'white',
        paddingLeft: 5,
        paddingTop: 7,
        paddingBottom: 6,
      },
      selected: {
        color: 'rgb(119, 136, 153)',
        fontSize: 15,
        fontWeight: 600,
        paddingTop: 5,
        paddingBottom: 5,
      },
    }

    var layout = this.props.layouts[this.props.selected_layout_index]
    var selected_room = this.props.rooms[this.props.selected_room_index]
    var self = this
    var room_selector = this.props.rooms.map(function (room) {
      var is_selected = room.id === selected_room.id
      return (
        <Common.Button
          key={room.name}
          label={room.name}
          onClick={function () {
            self.props.selectRoom(room.id)
          }}
          style={styles.button}
          selected={is_selected}
          selected_style={{ backgroundColor: '#555', color: 'white' }}
        />
      )
    })

    var layout_selector = null
    if (this.props.layouts.length > 1) {
      layout_selector = (
        <div style={m(styles.button, { float: 'right', margin: '8px 8px 8px 8px' })}>
          <select
            onChange={function (e) {
              self.props.selectLayout(e.target.value)
            }}
            style={{ color: 'white', outline: 'none', backgroundColor: 'transparent', borderColor: 'transparent' }}
          >
            {this.props.layouts.map(function (layout) {
              return (
                <option key={layout.layout_id} value={layout.layout_id} style={{ color: 'black' }}>
                  {layout.layout_id}
                </option>
              )
            })}
          </select>
        </div>
      )
    }

    var tables_selected = <div style={styles.message}>To create a combination, select tables and click "Add Combination"</div>
    var selected = []
    for (var id in this.props.selected_table_ids) {
      if (this.props.selected_table_ids[id]) {
        selected.push(this.props.id_to_code[id])
      }
    }

    if (selected.length > 0) {
      tables_selected = <div style={m(styles.message, styles.selected)}>Selected ({selected.join(', ')})</div>
    }

    var table_nodes = []
    var maxX = 0
    var maxY = 0
    for (var table_id in layout.table_items_config) {
      var table_position = layout.table_items_config[table_id]
      if (table_position.floorplan_room_id != selected_room.id) {
        continue
      }
      var code = table_position.code
      var table_size = selected_room.site_scale_factor * table_position.size
      // TODO border offsets
      var top = selected_room.site_scale_factor * table_position.y_coord
      var left = selected_room.site_scale_factor * table_position.x_coord
      var is_selected = Boolean(this.props.selected_table_ids[table_position.id])
      table_nodes.push(
        <Floorplan.Table
          media_url={this.props.media_url}
          key={table_position.id}
          id={table_position.id}
          code={code}
          size={table_size}
          top={top}
          left={left}
          shape={table_position.shape}
          selected={is_selected}
          onClick={this.props.clickTable}
        />
      )
      var tableMaxX = left + table_size
      var tableMaxY = top + table_size
      if (tableMaxX > maxX) {
        maxX = tableMaxX
      }
      if (tableMaxY > maxY) {
        maxY = tableMaxY
      }
    }
    styles.floorplan.minWidth = maxX + 20
    styles.floorplan.minHeight = maxY + 20

    var floorplan_img = selected_room.site_image_dict.raw ? '/.h/download/' + selected_room.site_image_dict.raw : null
    return (
      <div style={styles.floorplan_wrapper}>
        {tables_selected}
        <div>{room_selector}</div>
        {layout_selector}
        <div style={styles.floorplan}>
          {floorplan_img && <img src={floorplan_img} />}
          {table_nodes}
        </div>
      </div>
    )
  },
})

Floorplan.Table = React.createClass({
  getInitialState: function () {
    return { hover: false }
  },
  propTypes: {
    media_url: React.PropTypes.string.isRequired,
    id: React.PropTypes.string.isRequired,
    code: React.PropTypes.string.isRequired,
    top: React.PropTypes.number.isRequired,
    left: React.PropTypes.number.isRequired,
    size: React.PropTypes.number.isRequired,
    shape: React.PropTypes.string.isRequired,
    selected: React.PropTypes.bool.isRequired,
    onClick: React.PropTypes.func.isRequired,
  },
  onMouseOver: function () {
    this.setState({ hover: true })
  },
  onMouseOut: function () {
    this.setState({ hover: false })
  },
  render: function () {
    var styles = {
      container: {
        position: 'absolute',
        width: this.props.size,
        height: this.props.size,
        top: this.props.top,
        left: this.props.left,
      },
      code_style: {
        position: 'absolute',
        top: '50%',
        textAlign: 'center',
        left: 6,
        right: -6,
        marginTop: -4,
        cursor: 'pointer',
      },
      img_style: {
        width: this.props.size,
        height: this.props.size,
        margin: 4,
        padding: 2,
      },
    }

    var shape = this.props.shape === 'CIRCLE' ? 'circle-' : ''

    if (this.props.selected) {
      var border_radius = '25%'
      if (this.props.shape == 'CIRCLE') {
        var border_radius = '50%'
      }
      jQuery.extend(styles.img_style, {
        margin: 0,
        border: '4px solid lightslategray',
        MozBorderRadius: border_radius,
        WebkitBorderRadius: border_radius,
        KhtmlBorderRadius: border_radius,
        borderRadius: border_radius,
      })
    }

    styles.img_style = m(styles.img_style, this.state.hover && { cursor: 'pointer' })

    var table_img = this.props.media_url + 'images/floorplan/table-' + shape + 'white-400.png'

    var self = this
    // table class only used for easier handling of :hover
    return (
      <div
        onClick={function () {
          self.props.onClick(self.props.id)
        }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        style={styles.container}
      >
        <img style={styles.img_style} src={table_img} />
        <div style={styles.code_style}>{this.props.code}</div>
      </div>
    )
  },
})

TableCombos.SlideOut = React.createClass({
  propTypes: {
    selected_layout: React.PropTypes.object.isRequired,
    disabled: React.PropTypes.bool.isRequired,
    addCombo: React.PropTypes.func.isRequired,
    slideout_open: React.PropTypes.bool.isRequired,
    id_to_code: React.PropTypes.object.isRequired,
    unselectTable: React.PropTypes.func.isRequired,
    closeSlideOut: React.PropTypes.func.isRequired,
  },
  getInitialState: function () {
    return {
      table_ids: [],
      table_name: '',
      party_size_invalid: false,
      table_name_taken: false,
      repeated_combo: false,
    }
  },
  handleTableNameChange: function (event) {
    this.setState({ table_name: event.target.value })
  },
  onClick: function (event) {
    var party_size_invalid = false
    var table_name_taken = false
    var repeated_combo = false

    var min = parseInt(this.refs.party_size_min.getDOMNode().value)
    var max = parseInt(this.refs.party_size_max.getDOMNode().value)
    if (isNaN(min) || isNaN(max) || min < 1 || min > max) {
      party_size_invalid = true
    }

    var table_name = this.refs.table_name.getDOMNode().value

    for (var i in this.props.selected_layout.table_combos) {
      var combo = this.props.selected_layout.table_combos[i]
      if (combo.label === table_name) {
        table_name_taken = true
      }

      intersection = _.intersection(combo.table_ids, this.state.table_ids)
      if (intersection.length === combo.table_ids.length && intersection.length === this.state.table_ids.length) {
        repeated_combo = true
      }

      if (table_name_taken || repeated_combo) {
        break
      }
    }

    this.setState({
      party_size_invalid: party_size_invalid,
      table_name_taken: table_name_taken,
      repeated_combo: repeated_combo,
    })

    if (party_size_invalid || table_name_taken || repeated_combo) {
      return
    }

    this.props.addCombo(table_name, min, max)
  },
  componentWillReceiveProps: function (next_props) {
    var self = this
    var table_ids = []
    for (var id in next_props.selected_table_ids) {
      if (next_props.selected_table_ids[id]) {
        table_ids.push(id)
      }
    }

    var old_table_name = this.state.table_name
    var table_codes = this.state.table_ids.map(function (id) {
      return self.props.id_to_code[id]
    })
    var old_default_table_name = table_codes.sort().join('/')
    var table_name = this.state.table_name
    // only update table_name if it wasn't edited by user
    if (old_table_name === old_default_table_name) {
      var new_table_codes = table_ids.map(function (id) {
        return self.props.id_to_code[id]
      })
      table_name = new_table_codes.sort().join('/')
    }
    this.setState({ table_ids: table_ids, table_name: table_name })
  },
  styles: {
    table_combos_slideout: {
      width: 450,
      height: '100%',
      zIndex: 1000,
      position: 'fixed!important',
      top: 0,
      right: 0,
      WebkitBoxShadow: '0 0 9px 0 rgba(0, 0, 0, 0.3)',
      MozBoxShadow: '0 0 9px 0 rgba(0, 0, 0, 0.3)',
      boxShadow: '0 0 9px 0 rgba(0, 0, 0, 0.3)',
    },
    hidden: { right: -450 },
    header: {
      background: '#eee',
      color: '#333',
      borderBottom: '1px solid #efefef',
      height: 41,
      position: 'relative',
      textTransform: 'uppercase',
      fontSize: 16,
      fontFamily: styles_base.Oswald,
      lineHeight: '41px',
      paddingLeft: 10,
    },
    main_panel: {
      height: '100%',
      backgroundColor: 'white',
    },
    form_element: {
      paddingLeft: 20,
      paddingTop: 1,
    },
    inline: {
      display: 'inline-block',
      float: 'left',
    },
    span: {
      display: 'block',
      color: '#aaa',
      fontSize: 12,
      fontWeight: 700,
      margin: '10px 0 3px',
    },
    input: {
      borderColor: '#eaeaea',
      fontSize: 18,
      lineHeight: '30px',
      color: 'rgb(102, 102, 102)',
    },
    error: {
      color: 'red',
      paddingLeft: 20,
      height: 5,
    },
    clear: {
      clear: 'both',
    },
    selected_tables: {
      border: '1px solid #eaeaea',
      minHeight: 35,
      width: 395,
    },
    tag_box: {
      display: 'inline-block',
      backgroundColor: '#eaeaea',
      margin: '3px 2px',
      padding: '0 5px 0 5px',
      MozBorderRadius: 3,
      WebkitBorderRadius: 3,
      KhtmlBorderRadius: 3,
      borderRadius: 3,
    },
    tag_box_element: {
      display: 'inline-block',
      color: '#333',
      position: 'relative',
      top: -3,
      marginRight: 2,
    },
  },
  render: function () {
    var self = this
    var selected_tables = this.state.table_ids.map(function (id) {
      return (
        <div style={self.styles.tag_box}>
          <span style={m(self.styles.span, self.styles.tag_box_element, { display: 'inline-block' })}>{self.props.id_to_code[id]}</span>
          <Common.CloseButton
            onClick={function (e) {
              self.props.unselectTable(id)
            }}
            style={self.styles.tag_box_element}
          />
        </div>
      )
    })
    return (
      <div style={m(this.styles.table_combos_slideout, !this.props.slideout_open && this.styles.hidden)}>
        <div style={this.styles.header} className="header">
          <span style={m(this.styles.span, { margin: 0 })}>Add Combination</span>
          <Common.CloseButton
            onClick={this.props.closeSlideOut}
            style={{
              position: 'absolute',
              top: 0,
              right: 15,
              fontFamily: styles_base.lato,
              fontSize: 20,
              textTransform: 'none',
            }}
          />
        </div>
        <div style={this.styles.main_panel}>
          <div style={this.styles.form_element}>
            <span style={this.styles.span}>Table name</span>
            <input
              type="text"
              ref="table_name"
              value={this.state.table_name}
              style={this.styles.input}
              onChange={this.handleTableNameChange}
            />
          </div>
          <div style={this.styles.error}>{this.state.table_name_taken ? 'Table name already taken.' : ''}</div>
          <div style={this.styles.clear}></div>
          <div style={this.styles.form_element}>
            <span style={this.styles.span}>Table to combine</span>
            <div style={this.styles.selected_tables}>{selected_tables}</div>
          </div>
          <div style={this.styles.error}>{this.state.repeated_combo ? 'Combination already exists.' : ''}</div>
          <div style={this.styles.clear}></div>
          <div style={m(this.styles.form_element, this.styles.inline)}>
            <span style={this.styles.span}>Party size min</span>
            <input type="text" ref="party_size_min" style={this.styles.input} />
          </div>
          <div style={m(this.styles.form_element, this.styles.inline)}>
            <span style={this.styles.span}>Party size max</span>
            <input type="text" ref="party_size_max" style={this.styles.input} />
          </div>
          <div style={this.styles.clear}></div>
          <div style={this.styles.error}>{this.state.party_size_invalid ? 'Party size numbers are not valid.' : ''}</div>
          <Common.Button
            label="Add"
            onClick={this.onClick}
            disabled={this.props.disabled}
            style={{
              fontFamily: styles_base.lato,
              color: 'white',
              fontWeight: 400,
              fontSize: 14,
              position: 'absolute',
              left: 0,
              bottom: 0,
              backgroundColor: styles_base.primary,
              padding: 0,
              borderTop: '1px solid #eee',
              margin: '8px 0 8px 8px',
              textAlign: 'center',
              lineHeight: '35px',
              height: 35,
              width: 435,
              MozBorderRadius: 3,
              WebkitBorderRadius: 3,
              KhtmlBorderRadius: 3,
              borderRadius: 3,
            }}
          />
        </div>
      </div>
    )
  },
})
